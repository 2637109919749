import _ from 'lodash';

export const dashboardsAvailable = {
  'fulfillment': 'SLA e-Commerce Fulfillment',
  'receiving': 'SLA Receiving',
  'fulfillment-wholesale': 'SLA Wholesale Fulfillment',
  'rtb': 'Real Time Billing',
  'ship-via': 'ShipVia',
  'accountable-forecast': 'Forecast',
  'sla-ltm-ecommerce': 'SLA Last Touch Manufacturing',
  'inventory': 'Inventory'
};

export const dashboardTemplates = {
  'fulfillment': 'SLA Operations Dashboard - Fulfillment',
  'receiving': 'SLA Operations Dashboard - Receiving',
  'fulfillment-wholesale': 'SLA Wholesale',
  'rtb': 'Real Time Billing Dashboard',
  'sla-ltm-ecommerce': 'SLA Last Touch Manufacturing',
  'inventory': 'Inventory v1',
  'ship-via': 'ShipVia v1',
  'accountable-forecast': 'Accountable Forecasts v1',
};

export const availabilityFlags = {
  notConfigured: 'notConfigured',
  available: true,
  notAvailable: false,
  notMatched: 'notMatched'
};

export const dashboardStatusBuilder = (userDashboards, brandDashboards) => {
  const dashboardKeys = _.keys(dashboardsAvailable);
  return _.reduce(dashboardKeys, (acc, dashboardKey) => {
    if (!brandDashboards[dashboardKey]) {
      acc[dashboardKey] = availabilityFlags.notConfigured;
      return acc;
    }

    if (!userDashboards[dashboardKey]) {
      acc[dashboardKey] = availabilityFlags.notAvailable;
      return acc;
    }

    if (userDashboards[dashboardKey] === brandDashboards[dashboardKey]) {
      acc[dashboardKey] = availabilityFlags.available;
      return acc;
    }

    acc[dashboardKey] = availabilityFlags.notMatched;
    return acc;
  }, {});

};
