<template>
  <div
    id="dashboardUpdateModal"
    ref="dashboardUpdateModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Update dashboard
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <DashboardForm
            ref="dashboardForm"
            :updatedName="updatedName"
            :templates="dashboardTemplates"
            :brands="brand"
            :updatedProjectId="updatedProjectId"
            @successSave="onDashboardSave"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import DashboardForm from '../Dashboard/DashboardForm';
import { Modal } from 'bootstrap';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { dashboardTemplates } from '../CustomerPortalUsersManagement/constants';

export default {
  components: {
    DashboardForm
  },
  data() {
    return {
      modalUpdate: null,
      brand: null,
      dashboardTemplates: null,
      updatedName: null,
      updatedProjectId: null,
      dashboardId: null,
      dashboardLink: null
    };
  },
  computed: {
    ...mapGetters({
      dashboardTypes: 'dashboard/dashboardTypes',
    })
  },
  async mounted() {
    this.modalUpdate = new Modal(this.$refs['dashboardUpdateModal']);
    await this.fetchAllDashboardTypes();
  },
  methods: {
    ...mapActions({
      updateDashboard: 'dashboards/updateBrandDashboardData',
      fetchAllDashboardTypes: 'dashboard/fetchAllDashboardTypes',
      fetchDashboardsForBrand: 'dashboards/fetchDashboardsForBrand'
    }),
    initModal(dashboardName, brands, templates, projectId, dashboardId, dashboardLink) {
      this.modalUpdate.show();
      this.updatedName = dashboardName;
      this.brand = brands;
      this.dashboardTemplates = templates;
      this.updatedProjectId = projectId;
      this.dashboardId = dashboardId;
      this.dashboardLink = dashboardLink;
    },
    async onDashboardSave(dashboardToSave) {
      const dashKey = this.getDashboardKeyByTemplateId(dashboardToSave.templateId);
      const brand = _.first(this.brand);
      const updatedLink = this.updateDashboardLink(dashboardToSave['dashboardContentUrl']);
      const dataToSave = {
        id: this.dashboardId,
        brandName: brand.name,
        brandId: brand.id,
        brandCode: dashboardToSave['brandCode'],
        dashboardName: dashboardToSave['dashboardName'],
        dashboardKey: dashKey,
        dashboardLink: updatedLink
      };
      try {
        await this.updateDashboard({ dashboardItem: dataToSave, dashboardId: this.dashboardId });
        this.modalUpdate.hide();
        await this.fetchDashboardsForBrand(brand.id);
      } catch (e) {
        this.$toast.error('Failed to save the dashboard item.<br/>' + e);
      }
    },
    updateDashboardLink(workbookContentUrl) {
      const startIndex = this.dashboardLink.indexOf('views/') + 'views/'.length;
      const endIndex = this.dashboardLink.indexOf('/', startIndex);

      const dashboardLink = this.dashboardLink.substring(0, startIndex) +
        workbookContentUrl +
        this.dashboardLink.substring(endIndex);

      return dashboardLink.replace('/#/site', '/t' );
    },
    getDashboardKeyByTemplateId(id){
      const dashboardTemplate = _.find(this.dashboardTypes, (dt) => dt.id === id);
      let firstKey = '';
      {
        for (const [ key, value ] of Object.entries(dashboardTemplates)) {
          if (dashboardTemplate.name.includes(value)) {
            firstKey = key;
            break;
          }
        }
      }
      return firstKey;
    }
  }
};
</script>
