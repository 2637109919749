<template>
  <div class="container mt-5">
    <div class="row d-flex justify-content-center">
      <div class="col col-6">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            UPH settings
            <button
              v-if="isEditBtnVisible"
              class="btn btn-sm btn-primary"
              @click="editBrandUphClickHandler()"
            >
              Edit
            </button>
          </div>
          <div class="card-body w-100 p-5">
            <div class="profile-container">
              <TextInput
                v-model="uphItem.incentivePack"
                class="p-2"
                label="Incentive Pack"
                readonly
              />
              <TextInput
                v-model="uphItem.incentivePick"
                class="p-2"
                label="Incentive Pick"
                readonly
              />
              <TextInput
                v-model="uphItem.expectedPack"
                class="p-2"
                label="Expected Pack"
                readonly
              />
              <TextInput
                v-model="uphItem.expectedPick"
                class="p-2"
                label="Expected Pick"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <BrandUphEditModal
      ref="brandUphEditModal"
    />
  </div>
</template>
<script>
import TextInput from '@/components/common/TextInput';
import BrandUphEditModal from '../BrandUphEditModal';
import { mapActions, mapGetters } from 'vuex';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import { hasPrivilege } from '@/service/userAccess';

export default {
  components: {
    TextInput,
    BrandUphEditModal
  },
  props: ['brand'],
  computed: {
    ...mapGetters({
      uphItem: 'brandManagement/brandUph',
    }),
    isEditBtnVisible() {
      return hasPrivilege(userPrivileges.updateBrandUph);
    },
  },
  methods: {
    ...mapActions({
      fetchBrandUph: 'brandManagement/fetchBrandUph',
    }),
    async init() {
      await this.fetchBrandUph(this.brand.id);
    },
    editBrandUphClickHandler() {
      this.modalViewType = modalViewTypes.edit;
      if (this.uphItem.id != null) {
        this.$refs.brandUphEditModal.initModal(this.uphItem.id);
      } else {
        this.$refs.brandUphEditModal.initAddModal(this.brand.name, this.brand.code, this.brand.id);
      }
    },
  }
};
</script>
