<template>
  <div
    id="customer-portal-user-edit-modal"
    ref="customer-portal-user-edit-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="item.email"
              :errors="v$.item.email.$errors"
              description="Email"
              label="Email"
              :readonly="isEmailFieldReadonly"
              @blur="v$.item.email.$touch"
            />
            <SelectInput
              v-model="item.brandId"
              :errors="v$.item.brandId.$errors"
              description="Brand"
              label="Brand"
              :options="brandSelectorOptions"
              :disabled="isBrandSelectorDisabled"
              @change="brandSelectorChangeHandler"
              @blur="v$.item.brandId.$touch"
            />
            <TextInput
              v-if="isPasswordSectionVisible"
              v-model="password"
              :errors="v$.password.$errors"
              description="Password"
              label="Password"
              inputType="password"
              :disableAutocomplete="true"
              @blur="v$.password.$touch"
            />
            <TextInput
              v-if="isPasswordSectionVisible"
              v-model="passwordConfirmation"
              :errors="v$.passwordConfirmation.$errors"
              description="Password confirmation"
              label="Password confirmation"
              inputType="password"
              :disableAutocomplete="true"
              @blur="v$.passwordConfirmation.$touch"
            />
            <hr />
            <!-- dashboard configuration section !-->
            <div class="dashboards-container position-relative">
              <div
                v-if="isBrandFetchInProgress"
                class="w-100 h-100 position-absolute d-flex
                       justify-content-center align-items-center bg-light bg-opacity-75"
              >
                <Spinner />
              </div>
              <div class="mb-3">
                Dashboards
                <TooltippedIcon
                  iconClass="bi bi-info-circle"
                  tooltipText="To add a dashboard to the user profile, just check it in the are below."
                />
              </div>
              <CheckboxInput
                v-for="(v, key) in dashboards"
                :key="key"
                v-model="dashboards[key]"
                :label="dashboardsAvailable[key]"
                :disabled="dashboards[key] === availabilityFlags.notConfigured"
                :indeterminate="dashboards[key] === availabilityFlags.notMatched"
                :class="{ disabled: dashboards[key] === availabilityFlags.notConfigured }"
              >
                <template #icons-after>
                  <TooltippedIcon
                    v-if="dashboards[key] === availabilityFlags.notConfigured"
                    class="text-danger"
                    iconClass="bi bi-exclamation-triangle"
                    tooltipText="This dashboard is disabled because the chosen brand don't have such dashboard in his configuration"
                  />
                  <TooltippedIcon
                    v-if="dashboards[key] === availabilityFlags.notMatched"
                    class="text-warning"
                    iconClass="bi bi-exclamation-triangle"
                    tooltipText="The link in the user profile does not match with the link in brand configuration. To re-assign the dashboard link, please click on the checkbox."
                  />
                </template>
              </CheckboxInput>
            </div>
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              v-if="isDeleteButtonVisible"
              :disabled="isDeleteButtonDisabled"
              class="btn btn-danger"
              @click="showDeleteConfirmationModalHandler"
            >
              Delete
            </button>
            <button
              v-if="isSaveButtonVisible"
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="customer-portal-user-edit-confirmation-modal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-info"
        role="alert"
      >
        <h6>
          <i class="bi bi-info-circle" />
          The user should re-login to apply changes
        </h6>
      </div>
      <div
        v-if="showDashboardLinksNotMatchedWarning"
        class="alert alert-danger"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          Some dashboards in user profile does not match with the selected brand configuration.
          Please double check the configuration and save only if You really need it.
        </h6>
      </div>
      <div
        v-if="showDashboardLinksNotConfiguredWarning"
        class="alert alert-danger"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          Some dashboards for the selected brand is not configured, so the user not be able to
          access them. Please double check the configuration and save only if You really need it.
        </h6>
      </div>
    </template>
  </ConfirmationModal>
  <DeleteConfirmationModal
    ref="customer-portal-user-delete-confirmation-modal"
    :onCancelHandler="cancelDeleteHandler"
    :onConfirmHandler="deleteRecordHandler"
  >
    <template #alert-message>
      <h6>
        <i class="bi bi-exclamation-triangle" />
        Do you really want to delete this user?
        This operation can not be undone!
      </h6>
    </template>
  </DeleteConfirmationModal>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { Modal } from 'bootstrap';
import { useVuelidate } from '@vuelidate/core';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import DeleteConfirmationModal from '@/components/common/DeleteConfirmationModal';
import TextInput from '@/components/common/TextInput';
import SelectInput from '@/components/common/SelectInput';
import Spinner from '@/components/common/Spinner';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import {
  availabilityFlags,
  dashboardsAvailable,
  dashboardStatusBuilder
} from '@/components/CustomerPortalUsersManagement/constants';
import CheckboxInput from '@/components/common/CheckboxInput';
import TooltippedIcon from '@/components/common/TooltippedIcon';
import { minLength, requiredIf, email, required } from '@vuelidate/validators';
import { passwordStrengthValidator } from '@/lib/validators';
import { hasPrivilege } from '@/service/userAccess';

const defaultItem = {
  email: null
};

export default {
  components: {
    TooltippedIcon,
    CheckboxInput,
    ConfirmationModal,
    DeleteConfirmationModal,
    TextInput,
    Spinner,
    SelectInput
  },
  props: [ 'viewType', 'brandId' ],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      editModal: null,
      confirmationModalSave: null,
      confirmationModalDelete: null,
      initialized: false,
      isBrandFetchInProgress: false,
      dashboards: {},
      password: null,
      passwordConfirmation: null,
      originalBrand: null,
      item: {
        userId: null,
        email: null,
        brand: null,
        brandId: null,
        dashboardLinks: {}
      }
    };
  },
  validations() {
    return {
      password: {
        required: requiredIf(() => this.viewType === modalViewTypes.add),
        minLength: minLength(8),
        passwordStrengthValidator,
        shouldMatchWithConfirmation: {
          $validator: (value) => {
            return value === this.passwordConfirmation;
          },
          $message: 'The values for "password" and "password confirmation" should be the same'
        }
      },
      passwordConfirmation: {
        required: requiredIf(() => this.viewType === modalViewTypes.add),
        minLength: minLength(8),
        passwordStrengthValidator,
        shouldMatchWithConfirmation: {
          $validator: (value) => {
            return value === this.password;
          },
          $message: 'The values for "password" and "password confirmation" should be the same'
        }
      },
      item: {
        email: {
          required,
          email
        },
        brandId: { required },
      }
    };
  },
  computed: {
    ...mapGetters({
      brandsAvailable: 'brandManagement/allBrandNames',
      brandDashboards: 'dashboards/brandDashboards',
    }),
    showDashboardLinksNotMatchedWarning() {
      const dashboardStatuses = _.values(this.dashboards);
      const statusesWithWarning = [this.availabilityFlags.notMatched];
      const filtered = _.filter(dashboardStatuses, (s) => statusesWithWarning.includes(s));
      return filtered.length > 0;
    },
    showDashboardLinksNotConfiguredWarning() {
      const dashboardStatuses = _.values(this.dashboards);
      const statusesWithWarning = [this.availabilityFlags.notConfigured];
      const filtered = _.filter(dashboardStatuses, (s) => statusesWithWarning.includes(s));
      return filtered.length > 0;
    },
    brandDashboardsHash() {
      return _.reduce(this.brandDashboards, (acc, value) => {
        acc[value.dashboardKey] = value.dashboardLink;
        return acc;
      }, {});
    },
    availabilityFlags() {
      return availabilityFlags;
    },
    brandSelectorOptions() {
      return _.map(_.values(this.brandsAvailable), (i) => ({ key: i.id, value: i.name }));
    },
    modalTitle() {
      return this.viewType === modalViewTypes.edit ? 'Edit user' : 'Add New';
    },
    isEmailFieldReadonly() {
      return this.viewType === modalViewTypes.edit;
    },
    isPasswordSectionVisible() {
      return this.viewType === modalViewTypes.add;
    },
    isSaveButtonDisabled() {
      return !this.initialized;
    },
    isSaveButtonVisible() {
      return hasPrivilege(userPrivileges.updateCustomerPortalUser);
    },
    isDeleteButtonDisabled() {
      return !this.initialized ||
        this.viewType === modalViewTypes.add;
    },
    isDeleteButtonVisible() {
      return hasPrivilege(userPrivileges.deleteCustomerPortalUser);
    },
    isBrandSelectorDisabled() {
      return !!this.brandId;
    },
    dashboardsAvailable() {
      return dashboardsAvailable;
    }
  },
  async mounted() {
    this.editModal = new Modal(this.$refs['customer-portal-user-edit-modal']);
    this.confirmationModalSave = this.$refs['customer-portal-user-edit-confirmation-modal'].confirmationModal;
    this.confirmationModalDelete = this.$refs['customer-portal-user-delete-confirmation-modal'].confirmationModal;
    this.confirmationModalSave.hide();
    this.confirmationModalDelete.hide();
  },
  methods: {
    ...mapActions({
      fetchUserById: 'customerPortalUsersManagement/fetchUserById',
      fetchDashboardsForBrand: 'dashboards/fetchDashboardsForBrand',
      clearDashboardsStore: 'dashboards/clearStore',
      fetchCustomerPortalUsers: 'customerPortalUsersManagement/fetchUsers',
      updateCustomerPortalUser: 'customerPortalUsersManagement/updateUser',
      createCustomerPortalUser: 'customerPortalUsersManagement/createUser',
      deleteCustomerPortalUser: 'customerPortalUsersManagement/deleteUser',
      addForecastRecipient: 'forecast/addRecipient',
      deleteForecastRecipientByEmail: 'forecast/deleteRecipientByEmail',
      createTableauUser: 'dashboard/createTableauUser',
      updateTableauUserClientGroup: 'dashboard/updateTableauUserClientGroup',
      deleteTableauUser: 'dashboard/deleteTableauUser'
    }),
    async initModal(itemId) {
      this.initialized = false;
      this.clearDashboardsStore();
      this.editModal.show();
      const itemToEdit = itemId ? await this.fetchUserById(itemId) : { ...defaultItem };
      await this.updateItemData(itemToEdit);
      this.password = null;
      this.passwordConfirmation = null;
      this.initialized = true;
    },
    async brandSelectorChangeHandler() {
      this.isBrandFetchInProgress = true;
      this.dashboards = await this.buildDashboardsStatuses(this.item.dashboardLinks, this.item.brandId);
      this.isBrandFetchInProgress = false;
    },
    async updateItemData(newValue) {
      this.item.userId = newValue.userId;
      this.item.email = newValue.email;
      this.item.brand = newValue?.metadata?.brand?.name;
      const brandId = newValue?.metadata?.brand?.id;
      this.item.brandId = brandId ? parseInt(brandId) : null;
      this.originalBrand = newValue?.metadata?.brand?.id;
      if (!newValue.userId && this.brandId) {
        this.item.brandId = this.brandId;
      }
      this.item.dashboardLinks = newValue?.metadata?.dashboards || {};
      this.dashboards = await this.buildDashboardsStatuses(this.item.dashboardLinks, this.item.brandId);
    },
    async buildDashboardsStatuses(userDashboards, brand) {
      if (brand) {
        const brandItem = this.brandsAvailable[this.item.brandId];
        if (!brandItem || !brandItem.code) {
          this.$toast.warning('Selected brand not found');
          throw new Error('Selected brand not found');
        }
        await this.fetchDashboardsForBrand(brandItem.id);
      }

      const statuses = dashboardStatusBuilder(userDashboards, this.brandDashboardsHash);
      // for a new user all available dashboards should be selected by default;
      if (this.viewType === modalViewTypes.add) {
        return _.reduce(statuses, (acc, value, key) => {
          if (value === availabilityFlags.notAvailable) {
            acc[key] = availabilityFlags.available;
          } else {
            acc[key] = value;
          }
          return acc;
        }, {});
      }
      return statuses;
    },
    showDeleteConfirmationModalHandler() {
      this.editModal.hide();
      this.confirmationModalDelete.show();
    },
    cancelDeleteHandler() {
      this.confirmationModalDelete.hide();
      this.editModal.show();
    },
    async deleteRecordHandler() {
      try {
        await this.deleteForecastRecipientByEmail(this.item.email);
        await this.deleteCustomerPortalUser(this.item.userId);
        await this.deleteTableauUser({ username: this.item.email });
        await this.fetchCustomerPortalUsers(this.brandId);
      } catch (e) {
        this.$toast.error('Failed to delete item.');
      }
    },
    async showSaveConfirmationModalHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.editModal.hide();
      this.confirmationModalSave.show();
    },
    buildDashboards() {
      return _.reduce(this.dashboards, ((acc, value, key) => {
        switch (value) {
          case this.availabilityFlags.available:
            acc[key] = this.brandDashboardsHash[key];
            break;
          case this.availabilityFlags.notMatched:
            acc[key] = this.item.dashboardLinks[key];
            break;
          case this.availabilityFlags.notConfigured:
            if (this.item.dashboardLinks[key]) {
              acc[key] = this.item.dashboardLinks[key];
            }
            break;
        }
        return acc;
      }), {});
    },
    async updateBrandForecastsRecipients() {
      try {
        await this.addForecastRecipient({
          email: this.item.email,
          client: this.item.brand,
          brandId: this.item.brandId
        });
      } catch (e) {
        const message = e.message ? `: ${e.message}` : '';
        this.$toast.warning(`Failed to update forecast recipients list: ${message}.`);
      }
    },
    async saveChangesHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        return;
      }
      const metadata = {
        brand: {
          name: this.brandsAvailable[this.item.brandId].name,
          id: this.item.brandId
        },
        dashboards: this.buildDashboards()
      };

      const dataToSave = {
        userId: this.item.userId,
        email: this.item.email,
        metadata
      };
      const tableauDataToSave = {
        username: dataToSave.email,
        brandId: dataToSave.metadata.brand.id
      };

      try {
        if (dataToSave.userId) {
          await this.updateCustomerPortalUser(dataToSave);
          if (this.item.brandId !== this.originalBrand) {
            await this.updateTableauUserClientGroup(tableauDataToSave);
          }
        } else {
          dataToSave.password = this.password;
          await this.createCustomerPortalUser(dataToSave);
          await this.createTableauUser(tableauDataToSave);
        }

        if (this.item.brandId !== this.originalBrand || !this.originalBrand) {
          await this.updateBrandForecastsRecipients();
        }

        await this.fetchCustomerPortalUsers(this.brandId);
      } catch (e) {
        let message = e.message ? `: ${e.message}` : '.';
        if (e?.cause?.errorDetails?.status === 409) {
          const errorMessage = _.toLower(e?.cause?.errorDetails?.errors[0]);
          message = `: ${errorMessage}`;
        }
        this.editModal.show();
        this.$toast.error(`Failed to save the data${message}`);
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
      this.editModal.show();
    },
  }
};
</script>
<style>
.disabled {
  color: #b4aeae;
}
</style>
