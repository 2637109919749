<template>
  <div class="container mt-5">
    <div class="row d-flex justify-content-center">
      <div class="col col-6">
        <div class="card mb-4">
          <div class="card-header d-flex justify-content-between">
            Assigned locations
            <button
              v-if="isEditLocationsBtnVisible"
              class="btn btn-sm btn-primary"
              @click="editLocationsClickHandler"
            >
              Edit
            </button>
          </div>
          <div class="card-body w-100 p-2">
            <TextInput
              v-model="locationsAsString"
              class="p-2"
              readonly
            />
          </div>
        </div>
        <div class="card mb-3">
          <div class="card-header d-flex justify-content-between">
            Company type
            <button
              v-if="isEditBtnVisible"
              class="btn btn-sm btn-primary"
              @click="editCompanyTypeClickHandler"
            >
              Edit
            </button>
          </div>
          <div class="card-body w-100 p-2">
            <TextInput
              v-model="companyTypeMapping.companyType"
              class="p-2"
              readonly
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <CompanyTypeMappingEditModal
    ref="companyTypeMappingEditModal"
    :brand="brand.name"
    :brandCode="brand.code"
    :brandId="brand.id"
    :viewType="modalViewType"
    @successSave="init"
    @successDelete="init"
  />
  <BrandLocationEditModal
    ref="brandLocationEditModal"
    :brandId="brand.id"
  />
</template>
<script>
import TextInput from '@/components/common/TextInput';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import CompanyTypeMappingEditModal from '@/components/CompanyTypeMapping/CompanyTypeMappingEditModal';
import { mapActions, mapGetters } from 'vuex';
import { hasPrivilege } from '@/service/userAccess';
import BrandLocationEditModal from '@/components/BrandManagement/BrandLocationEditModal.vue';

export default {
  components: {
    BrandLocationEditModal,
    TextInput,
    CompanyTypeMappingEditModal
  },
  props: ['brand'],
  data() {
    return {
      modalViewType: null
    };
  },
  computed: {
    ...mapGetters({
      companyTypeMapping: 'companyTypeMapping/getCompanyTypeMapping',
      brandLocations: 'brandManagement/brandLocations'
    }),
    isEditBtnVisible() {
      return hasPrivilege(userPrivileges.updateCompanyTypeMappings);
    },
    isEditLocationsBtnVisible() {
      return hasPrivilege(userPrivileges.updateBrandLocation);
    },
    locationsAsString(){
      return this.brandLocations.join(', ');
    },
  },
  methods: {
    ...mapActions({
      fetchCompanyType: 'companyTypeMapping/fetchCompanyTypeByCompany',
      fetchBrandLocations: 'brandManagement/fetchBrandLocations'
    }),
    async init() {
      await this.fetchCompanyType(this.brand.id);
      await this.fetchBrandLocations(this.brand.id);
    },
    async editLocationsClickHandler() {
      await this.$refs.brandLocationEditModal.initModal();
    },
    async editCompanyTypeClickHandler() {
      if (this.companyTypeMapping.companyType !== null) {
        this.modalViewType = modalViewTypes.edit;
        await this.$refs.companyTypeMappingEditModal.initModal(this.companyTypeMapping.id);
      } else {
        this.modalViewType = modalViewTypes.add;
        await this.$refs.companyTypeMappingEditModal.initModal();
      }
    }
  }
};
</script>
