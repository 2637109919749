<template>
  <div class="d-flex align-items-center justify-content-center w-100 h-100">
    <img
      :src="params.value"
      alt="gravatar"
      style="height: 30px; width: 30px;"
    />
  </div>
</template>
<script>
export default {
};
</script>
