<template>
  <div
    id="brand-location-edit-modal"
    ref="brandLocationEditModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Edit locations
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <CheckBoxSelectorWithInputs
              v-model="locations"
              :errors="v$.locations.$errors"
              :options="locationOptions"
              :disabled="false"
              description="Choose locations"
              label="Brand's assigned locations"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="brandLocationEditConfirmationModal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The assigned locations list will be changed. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
</template>
<script>
import { mapActions } from 'vuex';
import ConfirmationModal from '../common/ConfirmationModal';
import Spinner from '../common/Spinner';
import { useVuelidate } from '@vuelidate/core';
import { Modal } from 'bootstrap';
import CheckBoxSelectorWithInputs from '@/components/common/CheckBoxSelectorWithPercentageInputs.vue';

export default {
  components: {
    CheckBoxSelectorWithInputs,
    ConfirmationModal,
    Spinner
  },
  props: ['brandId'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      modalViewType: null,
      confirmationModalSave: null,
      brandLocationsEditModal: null,
      initialized: false,
      locations: null,
      locationOptions: null
    };
  },
  validations() {
    return {
      locations: {}
    };
  },
  computed: {
    isSaveButtonDisabled() {
      return !this.initialized;
    }
  },
  async mounted() {
    this.brandLocationsEditModal = new Modal(this.$refs['brandLocationEditModal']);
    this.confirmationModalSave = this.$refs['brandLocationEditConfirmationModal'].confirmationModal;
    this.confirmationModalSave.hide();
  },
  methods: {
    ...mapActions({
      fetchLocations: 'brandManagement/fetchBrandLocations',
      updateLocations: 'brandManagement/updateBrandLocations',
      fetchAvailableLocations: 'brandManagement/fetchAvailableLocations'
    }),
    async initModal() {
      this.initialized = false;
      this.brandLocationsEditModal.show();
      const item = await this.fetchLocations(this.brandId);
      await this.initLocationOptions();
      this.updateItemData(item);
      this.initialized = true;
    },
    async initLocationOptions() {
      const locations = await this.fetchAvailableLocations() || [];
      this.locationOptions = locations.map((item) => ({ key: item, value: item }));
    },
    updateItemData(newValue) {
      this.locations = newValue;
    },
    async showSaveConfirmationModalHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.brandLocationsEditModal.hide();
      this.confirmationModalSave.show();
    },
    async saveChangesHandler() {
      try {
        const locationData = {
          brandId: this.brandId,
          locations: this.locations
        };
        await this.updateLocations(locationData);
        this.$toast.success('Locations successfully updated');
      } catch (e) {
        this.$toast.error('Failed to save changes.');
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    },
  }
};
</script>

