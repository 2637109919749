<template>
  <div class="row">
    <div class="col">
      <div
        v-if="brand.status !== 'INACTIVE'"
        class="card"
      >
        <div class="card-header d-flex justify-content-between">
          Customer Portal Users
          <button
            v-if="isAddBtnVisible"
            class="btn btn-sm btn-primary"
            @click="addNewCustomerPortalUserClickHandler"
          >
            Add new
          </button>
        </div>
        <div class="card-body p-0 ag-grid-container grid-container ">
          <CustomerPortalUsersManagementTable
            @rowClick="editCustomerPortalUserHandler"
          />
        </div>
      </div>
      <div v-else>
        <div
          class="alert alert-warning m-5"
          role="alert"
        >
          <h6>
            <i class="bi bi-exclamation-triangle" />
            The selected brand is not active. Configuration of users is not available.
          </h6>
        </div>
      </div>
    </div>
    <CustomerPortalUserEditModal
      ref="customer-portal-user-edit-modal"
      :viewType="modalViewType"
      :brandId="brand.id"
    />
  </div>
</template>
<script>
import CustomerPortalUsersManagementTable from '@/components/CustomerPortalUsersManagement/CustomerPortalUsersManagementTable';
import CustomerPortalUserEditModal from '@/components/CustomerPortalUsersManagement/CustomerPortalUserEditModal';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import { mapActions } from 'vuex';
import { hasPrivilege } from '@/service/userAccess';

export default {
  components: {
    CustomerPortalUsersManagementTable,
    CustomerPortalUserEditModal
  },
  props: ['brand'],
  data() {
    return {
      modalViewType: null
    };
  },
  computed: {
    isAddBtnVisible() {
      return hasPrivilege(userPrivileges.createCustomerPortalUser);
    }
  },
  methods: {
    ...mapActions({
      fetchCustomerPortalUsers: 'customerPortalUsersManagement/fetchUsers',
      fetchBrandNames: 'brandManagement/fetchBrandNames'
    }),
    async init() {
      if (this.brand.status === 'INACTIVE') {
        return;
      }

      await Promise.all([
        this.fetchCustomerPortalUsers(this.brand.id),
        this.fetchBrandNames()
      ]);
    },
    editCustomerPortalUserHandler(rowId) {
      this.modalViewType = modalViewTypes.edit;
      this.$refs['customer-portal-user-edit-modal'].initModal(rowId);
    },
    addNewCustomerPortalUserClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs['customer-portal-user-edit-modal'].initModal();
    },
  }
};
</script>
<style>
.grid-container {
  height: 600px;
  width: 100%;
}
</style>
