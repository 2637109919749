<template>
  <ag-grid-vue
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    :pagination="true"
    :paginationAutoPageSize="true"
    :rowData="rowData"
    class="ag-theme-alpine h-100"
  />
</template>

<script>

import { AgGridVue } from 'ag-grid-vue3';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import AgGreedImageCellRenderer from '@/components/common/AgGreedImageCellRenderer';
import { mapGetters } from 'vuex';
import { booleanIconCellRenderer } from '@/lib/agGridCellRenderers';
import { hasPrivilege } from '@/service/userAccess';
import { userPrivileges } from '@/components/constants';

export default {
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedImageCellRenderer,
  },
  emits: ['rowClick'],
  computed: {
    ...mapGetters({
      rowData: 'customerPortalUsersManagement/users'
    }),
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: { display: 'flex', 'align-items': 'center' }
      };
    },
    columnDefs() {
      return [
        {
          headerName: '',
          field: 'userId',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            clicked: (field) => {
              this.rowClickHandler(field);
            },
            visibilityCheck: () => {
              return hasPrivilege(userPrivileges.updateCustomerPortalUser);
            }
          },
          width: 80,
        },
        {
          headerName: 'Picture',
          field: 'picture',
          cellRenderer: 'AgGreedImageCellRenderer',
          width: 80,
        },
        {
          headerName: 'Email',
          field: 'email',
          width: 250,
          sortable: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'Brand',
          field: 'metadata.brand.name',
          width: 150,
          sortable: true,
          filter: 'agTextColumnFilter'
        },
        {
          field: 'metadata.dashboards.receiving',
          headerName: 'SLA Receiving',
          headerTooltip: 'SLA Receiving',
          cellRenderer: booleanIconCellRenderer(),
          width: 70,
          wrapText: true,
          autoHeight: true
        },
        {
          field: 'metadata.dashboards.fulfillment',
          headerName: 'SLA Ff e-Com',
          headerTooltip: 'SLA Fulfilment e-Commerce',
          cellRenderer: booleanIconCellRenderer(),
          width: 70,
          wrapText: true,
          autoHeight: true
        },
        {
          field: 'metadata.dashboards.fulfillment-wholesale',
          headerName: 'SLA Ff Wh',
          headerTooltip: 'SLA Fulfilment Wholesale',
          cellRenderer: booleanIconCellRenderer(),
          width: 70,
          wrapText: true,
          autoHeight: true
        },
        {
          field: 'metadata.dashboards.rtb',
          headerName: 'RTB',
          headerTooltip: 'Real Time Billing',
          cellRenderer: booleanIconCellRenderer(),
          width: 70,
          wrapText: true,
          autoHeight: true
        },
        {
          field: 'metadata.dashboards.ship-via',
          headerName: 'Ship Via',
          headerTooltip: 'Ship Via',
          cellRenderer: booleanIconCellRenderer(),
          width: 70,
          wrapText: true,
          autoHeight: true
        },
        {
          field: 'metadata.dashboards.accountable-forecast',
          headerName: 'Forecast',
          headerTooltip: 'Forecast',
          cellRenderer: booleanIconCellRenderer(),
          width: 70,
          wrapText: true,
          autoHeight: true
        },
        {
          field: 'metadata.dashboards.sla-ltm-ecommerce',
          headerName: 'SLA LTM e-Com',
          headerTooltip: 'SLA Last Touch Manufacturing (eCommerce)',
          cellRenderer: booleanIconCellRenderer(),
          width: 70,
          wrapText: true,
          autoHeight: true
        },
        {
          field: 'metadata.dashboards.inventory',
          headerName: 'Inventory',
          headerTooltip: 'Inventory',
          cellRenderer: booleanIconCellRenderer(),
          width: 70,
          wrapText: true,
          autoHeight: true
        }
      ];
    }
  },
  methods: {
    rowClickHandler(eventData) {
      this.$emit('rowClick', eventData);
    }
  }
};
</script>

