<template>
  <div
    id="create_new_dashboard_modal"
    ref="create_new_dashboard_modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Create new dashboard
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <DashboardForm
            :templates="dashboardTemplates"
            :brands="brand"
            @successSave="onDashboardSave"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import DashboardForm from '../Dashboard/DashboardForm';
import { Modal } from 'bootstrap';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { dashboardTemplates } from '../CustomerPortalUsersManagement/constants';
import { tableauDashboardBaseUrl } from '@/components/constants';

export default {
  components: {
    DashboardForm
  },
  data() {
    return {
      modalNew: null,
      brand: null,
      dashboardTemplates: null
    };
  },
  computed: {
    ...mapGetters({
      dashboardTypes: 'dashboard/dashboardTypes',
    }),
  },
  async mounted() {
    this.modalNew = new Modal(this.$refs['create_new_dashboard_modal']);
    await this.fetchAllDashboardTypes();
  },
  methods: {
    ...mapActions({
      saveDashboard: 'dashboards/createDashboardData',
      fetchAllDashboardTypes: 'dashboard/fetchAllDashboardTypes',
      fetchDashboardsForBrand: 'dashboards/fetchDashboardsForBrand',
      fetchViewById: 'dashboard/fetchViewById'
    }),
    initModal(brand, templates) {
      this.modalNew.show();
      this.brand = brand;
      this.dashboardTemplates = templates;
    },
    async onDashboardSave(dashboardToSave) {
      const dashboardKey = this.getDashboardKeyByTemplateId(dashboardToSave.templateId);
      const brand = _.first(this.brand);
      const dashboardLink = await this.buildDashboardLink(
        dashboardToSave.defaultViewId,
        dashboardToSave.dashboardContentUrl
      );

      const dataToSave = {
        id: null,
        brandName: brand.name,
        brandId: brand.id,
        brandCode: dashboardToSave['brandCode'],
        dashboardName: dashboardToSave['dashboardName'],
        dashboardKey,
        dashboardLink
      };
      try {
        await this.saveDashboard(dataToSave);
        this.modalNew.hide();
        await this.fetchDashboardsForBrand(brand.id);
      } catch (e) {
        this.$toast.error('Failed to save the dashboard item.<br/>' + e);
      }
    },
    getDashboardKeyByTemplateId(id){
      const dashboardTemplate = _.find(this.dashboardTypes, (dt) => dt.id === id);
      let firstKey = '';
      {
        for (const [ key, value ] of Object.entries(dashboardTemplates)) {
          if (dashboardTemplate.name.includes(value)) {
            firstKey = key;
            break;
          }
        }
      }
      return firstKey;
    },
    async buildDashboardLink(viewId, contentUrl) {
      const view = await this.fetchViewById(viewId);
      const viewUrlName = view['viewUrlName'];
      return tableauDashboardBaseUrl + contentUrl + '/' + viewUrlName;
    }
  }
};
</script>
