<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          UKG mapping
          <button
            v-if="isAddBtnVisible"
            class="btn btn-sm btn-primary"
            @click="ukgAddNewClickHandler"
          >
            Add new
          </button>
        </div>
        <div
          class="card-body p-0 w-100 d-flex justify-content-center"
          style="height: 20em"
        >
          <ag-grid-vue
            :columnDefs="ukgTableColumns"
            :defaultColDef="ukgTableColumnsDefaults"
            :rowData="ukgData"
            :overlayNoRowsTemplate="overlayNoRowsTemplateUKGTab"
            class="ag-theme-alpine h-100 w-100"
            sideBar="filters"
          />
        </div>
      </div>
    </div>
  </div>

  <UKGMappingEditModal
    ref="ukgMappingEditModal"
    :brand="brand.name"
    :brandCode="brand.code"
    :viewType="modalViewType"
    @successSave="onUKGConfigurationSave"
  />
</template>
<script>
import { AgGridVue } from 'ag-grid-vue3';

import UKGMappingEditModal from '@/components/UKGMapping/UKGMappingEditModal';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import { mapActions, mapGetters } from 'vuex';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import { hasPrivilege } from '@/service/userAccess';

export default {
  components: {
    AgGridVue,
    UKGMappingEditModal,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer,
  },
  props: ['brand'],
  data() {
    return {
      modalViewType: null,
    };
  },
  computed: {
    ...mapGetters({
      ukgData: 'ukgMapping/ukgMappingByBrand',
    }),
    isAddBtnVisible() {
      return hasPrivilege(userPrivileges.createUkgMapping);
    },
    ukgTableColumnsDefaults() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    ukgTableColumns() {
      return [
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            visibilityCheck() {
              return hasPrivilege(userPrivileges.updateUkgMapping);
            },
            clicked: (field) => {
              this.ukgRowClickHandler(field);
            }
          },
          width: 80
        },
        {
          headerName: 'ID',
          field: 'id',
          width: 80
        },
        {
          headerName: 'UKG Brand Name',
          field: 'ukgBrandName',
          width: 250,
          sortable: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'Deposco Brand Name',
          field: 'deposcoBrandName',
          width: 250,
          sortable: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'Deposco Company Code',
          field: 'deposcoCompanyCode',
          width: 200,
          sortable: true,
          filter: 'agTextColumnFilter'
        },
        {
          headerName: 'Uploaded Date',
          field: 'uploadedDate',
          width: 200,
          sortable: true
        }
      ];
    },
    overlayNoRowsTemplateUKGTab() {
      return '<div style="padding-top: 50px">There is no UKG configuration for the brand</div>';
    },
  },
  methods: {
    ...mapActions({
      fetchUKGMapping: 'ukgMapping/fetchUKGMappingByBrand',
    }),
    async init() {
      await this.fetchUKGMapping(this.brand.id);
    },
    async ukgRowClickHandler(itemId) {
      this.modalViewType = modalViewTypes.edit;
      await this.$refs.ukgMappingEditModal.initModal(itemId);
    },
    ukgAddNewClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs.ukgMappingEditModal.initModal();
    },
    async onUKGConfigurationSave() {
      await this.init();
    }
  }
};
</script>
