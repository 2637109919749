<template>
  <div class="position-relative">
    <div
      v-if="isLoadingData"
      class="d-flex justify-content-center align-items-center brand-management-spinner"
    >
      <Spinner />
    </div>
    <div class="d-flex justify-content-start button-back-container">
      <button
        class="btn btn-sm btn-primary"
        @click="backToBrandListClick()"
      >
        Back
      </button>
    </div>
    <div
      class="container-fluid "
      style="width: 75em"
    >
      <div class="d-flex justify-content-center pt-5">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">
                Brand name
              </th>
              <th scope="col">
                Brand code
              </th>
              <th scope="col">
                Type
              </th>
              <th scope="col">
                Created date
              </th>
              <th scope="col">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ brand.name }}</td>
              <td>{{ brand.code }}</td>
              <td>{{ brand.type }}</td>
              <td>{{ brand.createdDate }}</td>
              <td>{{ brand.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <GenericTabsLayout :tabsNumber="7">
        <template #page-header>
          <div class="d-flex justify-content-center">
            <h5 class=" mt-1 mb-0">
              Settings
            </h5>
          </div>
        </template>
        <template #tab-1-header>
          General
        </template>
        <template #tab-1-content>
          <GeneralTabContent
            ref="company-type-tab-content"
            :brand="brand"
          />
        </template>
        <template #tab-2-header>
          Dashboards
        </template>
        <template #tab-2-content>
          <DashboardsTabContent
            ref="dashboards-tab-content"
            :brand="brand"
          />
        </template>
        <template #tab-3-header>
          Forecast
        </template>
        <template #tab-3-content>
          <ForecastTabContent
            ref="forecast-tab-content"
            :brand="brand"
          />
        </template>
        <template #tab-4-header>
          SLA
        </template>
        <template #tab-4-content>
          <SLATabContent
            ref="sla-tab-content"
            :brand="brand"
          />
        </template>
        <template #tab-5-header>
          UPH settings
        </template>
        <template #tab-5-content>
          <UPHTabContent
            ref="uph-tab-content"
            :brand="brand"
          />
        </template>
        <template #tab-6-header>
          Users
        </template>
        <template #tab-6-content>
          <UsersTabContent
            ref="users-tab-content"
            :brand="brand"
          />
        </template>
        <template #tab-7-header>
          UKG
        </template>
        <template #tab-7-content>
          <UKGTabContent
            ref="ukg-tab-content"
            :brand="brand"
          />
        </template>
      </GenericTabsLayout>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/common/Spinner';
import { mapActions, mapGetters } from 'vuex';
import GenericTabsLayout from '../Layouts/GenericTabsLayout';
import DashboardsTabContent from './tabs/DashboardsTabContent';
import ForecastTabContent from './tabs/ForecastTabContent';
import SLATabContent from './tabs/SLATabContent';
import UPHTabContent from '@/components/BrandManagement/tabs/UPHTabContent';
import UsersTabContent from '@/components/BrandManagement/tabs/UsersTabContent';
import UKGTabContent from '@/components/BrandManagement/tabs/UKGTabContent';
import GeneralTabContent from '@/components/BrandManagement/tabs/GeneralTabContent.vue';

export default {
  components: {
    GeneralTabContent,
    GenericTabsLayout,
    Spinner,
    DashboardsTabContent,
    ForecastTabContent,
    SLATabContent,
    UKGTabContent,
    UsersTabContent,
    UPHTabContent,
  },
  data() {
    return {
      isLoadingData: false,
      brandId: this.$route.params.brandId,
      brand: {
        id: null,
        name: null,
        code: null,
        createdDate: null,
        status: null,
        type: null
      },
      modalViewType: null,
    };
  },
  computed: {
    ...mapGetters({
      rules: 'forecast/forecastRules',
    }),
  },
  async mounted() {
    this.isLoadingData = true;
    await this.fetchBrandDetailsByBrandId();
    await this.fetchTabs();
    this.isLoadingData = false;
  },
  methods: {
    ...mapActions({
      fetchBrandNames: 'brandManagement/fetchBrandNames',
      fetchBrandDetails: 'brandManagement/fetchBrand',
    }),
    async fetchTabs() {
      await Promise.all([
        this.$refs['company-type-tab-content'].init(),
        this.$refs['dashboards-tab-content'].init(),
        this.$refs['forecast-tab-content'].init(),
        this.$refs['sla-tab-content'].init(),
        this.$refs['uph-tab-content'].init(),
        this.$refs['users-tab-content'].init(),
        this.$refs['ukg-tab-content'].init()
      ]);
    },
    async fetchBrandDetailsByBrandId() {
      this.brand = await this.fetchBrandDetails(this.brandId);
    },
    backToBrandListClick() {
      return this.$router.push('/brand-management');
    },
  }
};
</script>

<style scoped>
.button-back-container {
  padding-top: 20px;
  padding-left: 30px;
}
</style>
