<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between">
      <button
        v-if="isCreateBtnVisible"
        class="btn btn-sm btn-primary"
        @click="createDashboardClickHandler"
      >
        Create new
      </button>
      <button
        v-if="isAddBtnVisible"
        class="btn btn-sm btn-primary"
        @click="addDashboardClickHandler()"
      >
        Add new
      </button>
    </div>
    <div
      v-if="dashboardData.length"
      class="card-body p-0 w-100 d-flex justify-content-center"
      style="height: 30em;"
    >
      <ag-grid-vue
        :columnDefs="dashboardsTableColumns"
        :defaultColDef="dashboardsTableColumnsDefault"
        :pagination="true"
        :paginationAutoPageSize="true"
        :rowData="dashboardData"
        class="ag-theme-alpine h-100 w-100"
        sideBar="filters"
      />
    </div>
    <BrandDashboardEditModal
      ref="brandDashboardEditModal"
      :viewType="modalViewType"
    />
    <CreateNewDashboardModal
      ref="create_new_dashboard_modal"
    />
  </div>
</template>
<script>
import _ from 'lodash';
import { AgGridVue } from 'ag-grid-vue3';
import { dashboardTemplates } from '@/components/CustomerPortalUsersManagement/constants';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import { mapActions, mapGetters } from 'vuex';
import BrandDashboardEditModal from '../BrandDashboardEditModal';
import CreateNewDashboardModal from '../CreateNewDashboardModal';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import { hasPrivilege } from '@/service/userAccess';

export default {
  components: {
    AgGridVue,
    BrandDashboardEditModal,
    CreateNewDashboardModal,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer,
  },
  props: ['brand'],
  data() {
    return {
      modalViewType: null
    };
  },
  computed: {
    ...mapGetters({
      dashboardData: 'dashboards/brandDashboards',
      dashboardTypes: 'dashboard/dashboardTypes'
    }),
    isAddBtnVisible() {
      return hasPrivilege(userPrivileges.createDashboard);
    },
    isCreateBtnVisible() {
      return hasPrivilege(userPrivileges.createTableauDashboard) &&
        hasPrivilege(userPrivileges.getTableauDashboardTemplates) &&
        this.dashboardKeys.length > 0;
    },
    dashboardKeys() {
      const brandDashboardKeys = _.map(this.dashboardData, (data) => (data.dashboardKey));
      const notExistedDashboards = _.difference(_.keys(dashboardTemplates), brandDashboardKeys);
      const dashboardsAvailable = _.values(_.pick(dashboardTemplates, notExistedDashboards));
      return  _.filter(this.dashboardTypes, (item) => {
        return dashboardsAvailable.some((substring) => item.name.includes(substring));
      });
    },
    dashboardsTableColumns() {
      return [
        {
          headerName: 'Dashboard name',
          field: 'dashboardName',
          sortable: true,
          width: 300
        },
        {
          headerName: 'Dashboard key',
          field: 'dashboardKey',
          width: 180,
          sortable: true
        },
        {
          headerName: 'Dashboard link',
          field: 'dashboardLink',
          width: 550,
          sortable: true
        },
        {
          headerName: '',
          field: 'id',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            buttonText: 'Edit',
            buttonVariant: 'btn-primary',
            visibilityCheck() {
              return hasPrivilege(userPrivileges.updateDashboard);
            },
            clicked: (id) => {
              this.editDashboardDataClickHandler(id);
            }
          },
          width: 80
        }
      ];
    },
    dashboardsTableColumnsDefault() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
  },
  methods: {
    ...mapActions({
      fetchAllDashboardTypes: 'dashboard/fetchAllDashboardTypes',
      fetchDashboardsForBrand: 'dashboards/fetchDashboardsForBrand',
    }),
    async init() {
      await Promise.all([
        this.fetchAllDashboardTypes(),
        this.fetchDashboardsForBrand(this.brand.id)
      ]);
    },
    createDashboardClickHandler() {
      this.$refs.create_new_dashboard_modal.initModal([this.brand], this.dashboardKeys);
    },
    addDashboardClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs.brandDashboardEditModal.initAddModal(this.brand.id, this.brand.code, this.brand.name);
    },
    editDashboardDataClickHandler(id) {
      this.modalViewType = modalViewTypes.edit;
      this.$refs.brandDashboardEditModal.initModal(id);
    },
  }
};
</script>
