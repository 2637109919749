<template>
  <div
    id="brand-dashboard-edit-modal"
    ref="brandDashboardEditModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="item.dashboardName"
              :errors="v$.item.dashboardName.$errors"
              description="Dashboard name"
              label="Dashboard name"
              @blur="v$.item.dashboardName.$touch"
            />
            <TextInput
              v-if="modalTitle === 'Edit dashboard data'"
              v-model="item.dashboardKey"
              :errors="v$.item.dashboardKey.$errors"
              description="Dashboard key"
              label="Dashboard key"
              readonly
            />
            <SelectInput
              v-else
              v-model="item.dashboardKey"
              class="dashboard-key-selector"
              :errors="v$.item.dashboardKey.$errors"
              :options="getDashboardKeys"
              description="Choose dashboard key"
              label="Dashboard key"
              @blur="v$.item.dashboardKey.$touch"
            />
            <TextInput
              v-model="item.dashboardLink"
              :errors="v$.item.dashboardLink.$errors"
              description="Dashboard link"
              label="Dashboard link"
              @blur="v$.item.dashboardLink.$touch"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              v-if="isDeleteButtonVisible"
              :disabled="isDeleteButtonDisabled"
              class="btn btn-danger"
              @click="showDeleteConfirmationModalHandler"
            >
              Delete
            </button>
            <button
              v-if="isUpdateByTemplateBtnVisible"
              aria-label="Close"
              class="btn btn-warning"
              @click="showUpdateModalHandler"
            >
              Update by template
            </button>
            <button
              v-if="isSaveButtonVisible"
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="brandDashboardEditConfirmationModal"
    :onCancelHandler="cancelHandler"
    :onConfirmHandler="saveHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The dashboard data will be changed. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
  <DeleteConfirmationModal
    ref="brandDashboardDeleteConfirmationModal"
    :onCancelHandler="cancelDeleteHandler"
    :onConfirmHandler="deleteRecordHandler"
  >
    <template #alert-message>
      Do you really want to delete this brand dashboard?
      This operation can not be undone!
    </template>
  </DeleteConfirmationModal>
  <DashboardUpdateByTemplateModal
    ref="dashboardUpdateModal"
  />
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { Modal } from 'bootstrap';
import { mapActions, mapGetters } from 'vuex';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import ConfirmationModal from '@/components/common/ConfirmationModal';
import DeleteConfirmationModal from '@/components/common/DeleteConfirmationModal';
import _ from 'lodash';
import SelectInput from '@/components/common/SelectInput';
import { dashboardsAvailable, dashboardTemplates } from '../CustomerPortalUsersManagement/constants';
import DashboardUpdateByTemplateModal from './DashboardUpdateByTemplateModal';
import { hasPrivilege } from '@/service/userAccess';

const defaultItem = {
  dashboardName: null,
  dashboardKey: null,
  dashboardLink: null
};

export default {
  name: 'BrandDashboardEditModal',
  components: {
    DashboardUpdateByTemplateModal,
    ConfirmationModal,
    DeleteConfirmationModal,
    TextInput,
    Spinner,
    SelectInput
  },
  props: ['viewType'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      confirmationModalSave: null,
      confirmationModalDelete: null,
      brandDashboardEditModal: null,
      initialized: false,
      dashboardId: null,
      workbook: null,
      item: {
        id: null,
        brandId: null,
        brandName: null,
        brandCode: null,
        dashboardName: null,
        dashboardKey: null,
        dashboardLink: null
      },
    };
  },
  validations() {
    return {
      item: {
        dashboardName: { required },
        dashboardKey: { required },
        dashboardLink: { required }
      }
    };
  },
  computed: {
    ...mapGetters({
      dashboardData: 'dashboards/brandDashboards',
      templates: 'dashboard/dashboardTypes'
    }),
    isUpdateByTemplateBtnVisible() {
      return hasPrivilege(userPrivileges.createTableauDashboard);
    },
    modalTitle() {
      return this.viewType === modalViewTypes.edit ? 'Edit dashboard data' : 'Add dashboard data';
    },
    isSaveButtonDisabled() {
      return !this.initialized;
    },
    isSaveButtonVisible() {
      return hasPrivilege(userPrivileges.updateDashboard);
    },
    isDeleteButtonDisabled() {
      return !this.initialized ||
        this.viewType === modalViewTypes.add;
    },
    isDeleteButtonVisible() {
      return hasPrivilege(userPrivileges.deleteDashboard);
    },
    getDashboardKeys() {
      const dashboardKeys = _.map(this.dashboardData, (data) => (data.dashboardKey));
      const result = Object.keys(dashboardsAvailable)
        .filter((key) => !dashboardKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = dashboardsAvailable[key];
          return obj;
        }, []);
      return _.map(_.toPairs(result), ([ key, value ]) => ({ key, value }));
    },
    getWorkbookContentUrl() {
      const startIndex = this.item.dashboardLink.lastIndexOf('views/') + 'views/'.length;
      const endIndex = this.item.dashboardLink.lastIndexOf('/');
      return this.item.dashboardLink.substring(startIndex, endIndex);
    },
  },
  async mounted() {
    this.brandDashboardEditModal = new Modal(this.$refs['brandDashboardEditModal']);
    this.confirmationModalSave = this.$refs['brandDashboardEditConfirmationModal'].confirmationModal;
    this.confirmationModalDelete = this.$refs['brandDashboardDeleteConfirmationModal'].confirmationModal;
    this.dashboardUpdateModal = this.$refs['dashboardUpdateModal'].modalUpdate;
    this.dashboardUpdateModal.hide();
    this.confirmationModalSave.hide();
    this.confirmationModalDelete.hide();
  },
  methods: {
    ...mapActions({
      fetchBrandDashboardDataById: 'dashboards/fetchBrandDashboardDataById',
      deleteBrandDashboardData: 'dashboards/deleteBrandDashboardData',
      updateBrandDashboardData: 'dashboards/updateBrandDashboardData',
      fetchDashboardsForBrand: 'dashboards/fetchDashboardsForBrand',
      addBrandDashboardData: 'dashboards/createDashboardData',
      fetchWorkbookByContentUrl: 'dashboard/fetchWorkbookByContentUrl'
    }),
    async fetchWorkbook() {
      try {
        this.workbook = await this.fetchWorkbookByContentUrl(this.getWorkbookContentUrl);
      } catch (e) {
        this.$toast.error(e);
      }
    },
    async fetchExistDashboardData() {
      await this.fetchDashboardsForBrand(this.item.brandId);
    },
    async initModal(dashboardId) {
      this.initialized = false;
      this.dashboardId = dashboardId;
      this.brandDashboardEditModal.show();
      const itemToEdit = dashboardId ? await this.fetchBrandDashboardDataById(dashboardId) : { ...defaultItem };
      this.updateItemData(itemToEdit);
      this.initialized = true;
    },
    initAddModal(brandId, brandCode, brandName) {
      this.initialized = false;
      this.dashboardId = null;
      this.brandDashboardEditModal.show();
      const itemToAdd = {
        brandId,
        brandCode,
        brandName
      };
      this.updateItemData(itemToAdd);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.item.id = newValue.id;
      this.item.brandId = newValue.brandId;
      this.item.brandName = newValue.brandName;
      this.item.brandCode = newValue.brandCode;
      this.item.dashboardName = newValue.dashboardName;
      this.item.dashboardKey = newValue.dashboardKey;
      this.item.dashboardLink = newValue.dashboardLink;
    },
    async showSaveConfirmationModalHandler() {
      const isFormCorrect = await this.v$.item.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.brandDashboardEditModal.hide();
      this.confirmationModalSave.show();
    },
    async saveHandler() {
      const dataToSave = {
        ...this.item
      };
      try {
        if (dataToSave.id!= null) {
          await this.updateBrandDashboardData({ dashboardItem: dataToSave, dashboardId: this.dashboardId });
          this.$toast.success('Brand dashboard successfully updated');
        }
        else {
          await this.addBrandDashboardData(dataToSave);
          this.$toast.success('Brand dashboard successfully created');
        }
        await this.fetchExistDashboardData();
      } catch (e) {
        this.$toast.error('Failed to save the dashboard item.<br/>' + e);
      }
    },
    cancelHandler() {
      this.confirmationModalSave.hide();
    },
    showDeleteConfirmationModalHandler() {
      this.brandDashboardEditModal.hide();
      this.confirmationModalDelete.show();
    },
    cancelDeleteHandler() {
      this.confirmationModalDelete.hide();
      this.brandDashboardEditModal.show();
    },
    async deleteRecordHandler() {
      try {
        await this.deleteBrandDashboardData(this.dashboardId);
        this.$toast.success('Brand dashboard successfully deleted');
        await this.fetchExistDashboardData();
      } catch (e) {
        this.$toast.error('Failed to delete item.');
      }
    },
    getTemplateForUpdate() {
      const templateName = _.find(dashboardTemplates, (value, key) => key === this.item.dashboardKey);
      return _.filter(this.templates, (item) => {
        return item.name.includes(templateName);
      });
    },
    async showUpdateModalHandler() {
      await this.fetchWorkbook();
      this.$refs.dashboardUpdateModal.initModal(
        this.item.dashboardName,
        [{ id: this.item.brandId, name: this.item.brandName, code: this.item.brandCode }],
        this.getTemplateForUpdate(),
        this.workbook.projectId,
        this.dashboardId,
        this.item.dashboardLink
      );
      this.brandDashboardEditModal.hide();
    }
  }
};
</script>

<style scoped>
.dashboard-key-selector label {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
}
</style>
