<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          SLA Configuration
          <button
            v-if="isAddBtnVisible"
            class="btn btn-sm btn-primary"
            @click="addNewSlaConfigurationClickHandler"
          >
            Add new
          </button>
        </div>
        <div
          class="card-body p-0"
          style="height: 12em"
        >
          <ag-grid-vue
            :columnDefs="slaTableColumnsDef"
            :defaultColDef="slaTableColumnsDefaults"
            :rowData="slaRule"
            :overlayNoRowsTemplate="overlayNoRowsTemplateSLATab"
            class="ag-theme-alpine w-100 h-100"
          />
        </div>
      </div>
    </div>
    <SlaEditModal
      ref="slaEditModal"
      :brand="brand.code"
      :brandId="brand.id"
      :viewType="modalViewType"
      @successSave="onSlaConfigurationSave"
    />
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue3';
import { slaGreedColumns } from '@/components/SlaConfiguration/constants';
import { mapActions, mapGetters } from 'vuex';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import SlaEditModal from '@/components/SlaConfiguration/SlaEditModal';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import { hasPrivilege } from '@/service/userAccess';

export default {
  components: {
    AgGridVue,
    SlaEditModal,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer,
  },
  props: ['brand'],
  data() {
    return {
      modalViewType: null
    };
  },
  computed: {
    ...mapGetters({
      slaRule: 'sla/slaConfigItemByBrandId',
    }),
    isAddBtnVisible() {
      return hasPrivilege(userPrivileges.createSlaRule) &&
        this.slaRule?.length < 1;
    },
    slaTableColumnsDefaults() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    slaTableColumnsDef() {
      if (!hasPrivilege(userPrivileges.updateSlaRule)) {
        return ;
      }
      return slaGreedColumns({
        rowClickHandler: this.rowClickHandler
      });
    },
    overlayNoRowsTemplateSLATab() {
      return '<div style="padding-top: 100px">There is no SLA configuration for the brand</div>';
    },
  },
  methods: {
    ...mapActions({
      fetchSlaRule: 'sla/fetchSlaConfigItemByBrandId',
    }),
    async init() {
      await this.fetchSlaRule(this.brand.id);
    },
    addNewSlaConfigurationClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs.slaEditModal.initModal();
    },
    async rowClickHandler(itemId) {
      this.modalViewType = modalViewTypes.edit;
      await this.$refs.slaEditModal.initModal(itemId);
    },
    async onSlaConfigurationSave() {
      await this.fetchSlaRule(this.brand.id);
    },
  }
};
</script>
